import axios from "axios"
import { environment } from '@/stores/environment';

export const CategoryDBMixin = {
  data() {
    return {
      baseUrl: `${environment.API_URL}/site`, // Base URL for the API
    };
  },
  methods: {
    async axiosInstance() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = `${environment.API_TOKEN}`;
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async getAllCategories() {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/categorias`);
        // console.log("Todas as categorias.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },

    async getCategoryGroups(name) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/categorias/?id=${name}`);
        // console.log("Grupos da categoria.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },

    async getByBusinessGroups(name) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/tiposnegocio/?id=${name}`);
        // console.log("Grupos do tipo de negocio.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
