import axios from "axios"
import { environment } from '@/stores/environment';

export const ConfigDBMixin = {
  data() {
    return {
      baseUrl: `${environment.API_URL}/site`, // Base URL for the API
    };
  },
  methods: {
    async axiosDefaultConfigInstance() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = `${environment.API_TOKEN_DEFAULT}`;
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async axiosConfigInstance() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = `${environment.API_TOKEN}`;
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async getStoreConfig() {
      try {
        const instance = await this.axiosConfigInstance();
        const response = await instance.get(`/configuracoes`);
        // console.log("Configurações da Loja.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getMenuItems() {
      try {
        const instance = await this.axiosDefaultConfigInstance();
        const response = await instance.get(`/configuracoes`);
        const filteredMenuItems = response.data.filter(item => item.tipo === "Menu");
        // console.log("Items do Menu.", response.data);
        return filteredMenuItems;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getByBusiness() {
      try {
        const instance = await this.axiosDefaultConfigInstance();
        const response = await instance.get(`/tiposnegocio`);
        // console.log("Produtos por negocio.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
