<template>
  <div @click="handleClickOutside" class="megamenu-wrapper">
    <div v-if="isLoading" class="loading-menu">
      <img src="@/assets/icons/loading.svg" alt="Carregando" />
    </div>
    <div class="main-categories">
      <div
        v-for="category in categories"
        :key="category.id"
        @click="changeMainCategory(category.id)"
        :class="['main-category', isActive(category.id) ? 'active' : '']"
      >
        <div class="label">
          <div>
              {{ category.nome }}
          </div>
          <div>
              <img :src="chevronRight" />
          </div>
          
        </div>
      </div>
    </div>
    <div class="sub-categories">
      <div @click="hideMegaMenu" v-for="group in categoryGroups" :key="group.id" class="sub-category">
        <router-link :to="{ name: 'produto', params: { groupId: group.id, groupName: group.nome } }">    
          <!-- <div class="icon"><img :src="group.imagem" /></div> -->
          <div class="label">
            {{ group.nome }}
          </div>
        </router-link>
        <small v-if="group.lancamento == 'Sim'" class="tag-release">Lançamento</small>
        <small v-if="group.mais_vendido == 'Sim'" class="tag-sale">Mais Vendido</small>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus";
import {CategoryDBMixin} from '@/database/category.js'
import chevronRight from '@/assets/icons/chevron-right.svg'

export default {
  name: "MegaMenu",
  mixins: [CategoryDBMixin],
  data() {
    return {
      chevronRight,
      categories: [],
      categoryGroups: [],
      activeMainCategory: null,
      lastX: 0,
      lastY: 0,
      direction: "",
      throttle: false,
      isLoading: true,
    };
  },
  methods: {
    async getCategories() {
        const data = await this.getAllCategories();
        // console.log("MENU cat", data)
        this.categories = data;
        this.isLoading = false;
        //this.activeMainCategory = this.categories[0].id
    },
    async loadCategoryGroups(name) {
        this.isLoading = true;
        let data = []
        data = await this.getCategoryGroups(name);
        this.categoryGroups = data[0].grupos
        // console.log("Grupos carregados", this.categoryGroups)
        this.isLoading = false;
    },
    hideMegaMenu() {
      eventBus.emit("hide-mega-menu");
    },
    isActive(key) {
      return this.activeMainCategory === key;
    },
    mouseDirection(e) {
      if (!this.throttle) {
        this.throttle = true;
        let theta = Math.abs(
          (180 * Math.atan2(e.pageY - this.lastY, e.pageX - this.lastX)) /
            Math.PI
        );
        this.direction = theta > 75 ? "vertical" : "horizontal";
        this.lastX = e.pageX;
        this.lastY = e.pageY;
        setTimeout(() => {
          this.throttle = false;
        }, 50);
      }
    },
    changeMainCategory(index) {
      // if (this.direction === "vertical") {
        this.activeMainCategory = index;
      // }
      // console.log("teste", index)
      this.loadCategoryGroups(index)
    },
    handleClickOutside(event) {
      if (!event.target.closest('.megamenu-wrapper')) {
        this.hideMegaMenu();
      }
    },
  },
  mounted() {
    window.addEventListener("mousemove", this.mouseDirection);
    document.addEventListener('click', this.handleClickOutside);
    this.getCategories();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mouseDirection);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.tag-release {
  background: #79b279;
  color: #fff;
  padding: 5px;
  border-radius: 0 8px 8px 0;
  margin-left: 5px;
  font-size: 0.7rem;
  font-weight: 600;
}
.tag-sale {
  background: #e38804;
  color: #fff;
  padding: 5px;
  border-radius: 0 8px 8px 0;
  margin-left: 5px;
  font-size: 0.7rem;
  font-weight: 600;
}
.loading-menu {
  position: absolute;
    left: 0;
    right: 0;
    margin: 100px auto;
    img {
      width: 100px;
    }
}
.megamenu-wrapper {
  border: 1px solid #a4a4a4;
  box-shadow: 4px 15px 20px -21px;
  margin: auto;
  background: #f8f8f8;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  width: 95%;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 9999;
  min-height: 400px;
  max-width: 1400px;
}

.main-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  background: #efefef;
}

.main-category {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0 0px 0 30px;
  .label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 20px;
  }
}
.main-category:hover {
  background: #FF463A;
  color: #fff;
}
.main-category {
  .label > div{
    font-weight: 500;
  }
}

.main-category:not(:last-of-type) {
  // border-bottom: 1px solid #c2c2c2;
}

.main-category .icon {
  margin-right: 15px;
}

.main-category.active {
  background: #ff4341;
  color: #fff;
  font-weight: 500;
  label{
    font-weight: 500;
  }
}

.sub-categories {
  background: #fff;
  display: grid;
  padding: 25px 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 0px 20px;
  text-align: left
}

.sub-category {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  a {
    color: #000;
  }
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.sub-category:hover {
  background: #dedede;
}

.sub-category .label {
  margin-top: 15px;
  font-weight: 400;
}
</style>