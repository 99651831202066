<template>
  <div class="main">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        :multiple="allowMultiple"
        name="file"
        :id="uniqueId"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png,.psd,.ai,.cdr"
      />

      <label :for="uniqueId" class="file-label">
        <img src="@/assets/icons/file.png" alt="Padão Color - Envio de Arte" />
        <div v-if="isDragging">Solte seu arquivo aqui.</div>
        <div v-else>
        <span v-if="auxText">{{auxText}} ou </span>
        <span v-else>Arraste seu arquivo aqui ou </span>
        <span class="span-link">selecione</span> para fazer o upload.</div>
      </label>

      <div class="sending-file" v-if="uploadingFile">
        <img src="@/assets/icons/sending-file.gif" alt="Padão Color - Envio de Arte" />
        <p>Enviando Arquivo</p>
      </div>

      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div class="preview-card-img-container">
            <img class="preview-img" :src="generateThumbnail(file)" />
            <p :title="file.name">
              {{ makeName(file.name) }}
            </p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HelpersApiMixin} from '@/database/helpers.js'

export default {
  mixins: [HelpersApiMixin],
  props: {
      attachType: String,
      auxText: String,
      allowMultiple: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      uploadingFile: false,
      isDragging: false,
      files: [],
      uniqueId: 'fileInput-' + Math.random().toString(36).substr(2, 9)
    };
  },
  methods: {
    async uploadFiles() {
        const files = this.files;
        const formData = new FormData();
        this.uploadingFile = true
        for (const file of files) {
        // files.forEach((file) => {
            // formData.append("selectedFiles", file);
            // console.log("DROP", file)
            const hardTimestampedName = this.appendTimestampToFileName(file.name);

            // Split the name and the extension
            const parts = hardTimestampedName.split('.');
            const extension = parts.pop(); // Extract the extension (last part)
            const nameWithoutExtension = parts.join('.'); // Rejoin the name parts

            // Remove all non-alphanumeric characters (except underscore) from the name part
            const cleanedName = nameWithoutExtension.replace(/[^\w]/g, '');

            // Reattach the extension with a dot
            const timestampedName = `${cleanedName}.${extension}`;
            await this.bunnyUpload(timestampedName, files[0], file.type);
            this.$emit('file-uploaded', timestampedName, this.attachType);
        };
        // console.log("arquivo", files);
        this.uploadingFile = false
    },
    onChange() {
      this.$emit('files-selected', this.files);
      if (this.allowMultiple) {
        const newFiles = Array.from(this.$refs.file.files);
        this.files = [...this.files, ...newFiles];
        this.uploadFiles();
      } else {
        this.files = [...this.$refs.file.files];
        this.uploadFiles()
      }
    },
    appendTimestampToFileName(fileName) {
      const timestamp = Date.now();
      const dotIndex = fileName.lastIndexOf('.');
      if (dotIndex === -1) {
        // No extension
        return `${fileName}-${timestamp}`;
      } else {
        const name = fileName.substring(0, dotIndex);
        const extension = fileName.substring(dotIndex);
        return `${name}-${timestamp}${extension}`;
      }
    },

    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },

    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },

    remove(i) {
      this.files.splice(i, 1);
      this.$emit('remove-file');
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      if (this.allowMultiple) {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files);
        this.files = [...this.files, ...newFiles];
        this.uploadFiles();
        this.isDragging = false;
      } else {
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.onChange();
        this.isDragging = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* .main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
} */
.sending-file {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fa4431;
  p {
    font-weight: 600;
  }
}
.dropzone-container {
  padding: 2rem;
    background: #EFEFEF;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    color: #878787;
  
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 15px;
    display: block;
    cursor: pointer;
    text-align: center;
    .span-link {
        color: #FF463A;
        text-decoration: underline
    }
}
.preview-container {
  display: flex;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  padding: 5px;
  margin-left: 5px;
  width: 100%;
  .preview-card-img-container {
    flex: auto;
  }
  button {
    border: 0;
    border-radius: 7px;
    font-weight: 900;
    font-size: 20px;
    color: #FF463A;
    border: 1px solid #FF463A;
    line-height: 20px;
  }
}
.preview-img {
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>