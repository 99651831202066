<template>
  <TopHeader v-if="$route.meta.topheader != false" class="hide-on-mobile" />
  <Navigation v-if="$route.meta.navigation != false" />
  <MegaMenu />
  <router-view />
</template>

<script>
import Home from './components/Home.vue'
import TopHeader from './components/TopHeader.vue'
import Navigation from './components/Navigation.vue'

import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    TopHeader,
    Home,
    Navigation,
  },
  mounted() {
      const wapp = document.createElement("script");
      wapp.setAttribute("type", "application/javascript");
      wapp.setAttribute(
          "src",
          "https://kong.tallos.com.br:18000/megasac-api/whatsapp-button/6582128ece54fb401b67d0 41-5521971148565-1.min.js"
      );
      
      document.head.appendChild(wapp);
  },
}
</script>

<style>
@import "./assets/styles/main.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #F5F5F5;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
