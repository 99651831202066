<template>
    <div class="container-full">
        <Splide :options="{ rewind: true, autoplay: true }" aria-label="My Favorite Images" class="hide-on-mobile">
            <SplideSlide v-for="slide in imageslides[0]" :key="slide">
                <a :href="slide.url">
                    <img :src="slide.imagem_desktop" style="max-width: 100%" />
                </a>
            </SplideSlide>
            <div class="splide__progress">
                <div class="splide__progress__bar">
                </div>
            </div>

            <button class="splide__toggle">
                <span class="splide__toggle__play">Play</span>
                <span class="splide__toggle__pause">Pause</span>
            </button>
        </Splide>
        <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile">
            <SplideSlide v-for="slide in bannerMobile[0]" :key="slide">
                <a :href="slide.url">
                    <img :src="slide.imagem_mobile" style="max-width: 100%" />
                </a>
            </SplideSlide>
        </Splide>
        <main class="container">
            <div class="row align-items-end hide-buttons-on-mobile mt-4">
                <template v-for="(img, index) in buttonsFirstLine" :key="img">
                    <div :class="['col-12', index === 0 ? 'col-md-6' : 'col-md-3']">
                        <template v-if="img.url">
                            <a v-if="img.nova_pagina == 'Não'" :href="img.url">
                                <img :src="img.imagem" style="max-width: 100%" />
                            </a>
                            <a v-else target="_blank" :href="img.url">
                                <img :src="img.imagem" style="max-width: 100%" />
                            </a>
                        </template>
                        <img v-else :src="img.imagem" style="max-width: 100%" />
                    </div>
                </template>
            </div>
            <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile mx-2">
                <SplideSlide v-for="slide in buttonsFirstLine" :key="slide">
                    <a v-if="slide.url" :href="slide.url">
                        <img :src="slide.imagem" style="max-width: 100%" />
                    </a>
                    <img v-else :src="slide.imagem" style="max-width: 100%" />
                </SplideSlide>
            </Splide>
            <section class="row grupo-produtos">
                <template v-for="(category, index) in highligthCats" :key="category">
                    <div class="grupo-produtos-col">
                        <router-link :to="{ name: 'categoria', params: { categoryId: category.id, categoryName: category.nome } }">
                            <img :src="category.imagem" style="max-width: 100%" />
                        </router-link>
                    </div>
                </template>
            </section>
            <section class="mt-5">
                <h2 class="section-title">Os mais vendidos</h2>
                <div class="row">
                    <div v-for="produto in highligthProducts" class="product-card">
                        <img v-if="isLoading" src="@/assets/icons/loading.svg" alt="Carregando" />
                        <img v-else :src="produto.imagem_1" />
                        <h5 class="my-2">{{produto.nome}}</h5>
                        <div class="product-detail">
                            <template v-for="(variacao, index) in produto.variacoes">
                                <span class="variacoes">{{index}}: {{variacao}}</span>
                            </template>
                            <div class="price-container">
                                <span class="price mt-2">{{formatCurrency(produto.preco.economico)}}</span>
                                <span class="variacoes">{{produto.unidade}}</span>
                            </div>
                        </div>
                        <div class="button-container mt-4">
                            <div class="col-number">
                                <vue-number-input v-model="produto.initialQty" size="small" :min="1" inline controls></vue-number-input>
                            </div>
                            <div class="col-buybutton">
                                <button class="btn btn-primary" @click="openModal(produto)">Comprar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    v-if="isModalOpen"
                    header="Cadastrar Dados de Execução"
                    :product="selectedProduct"
                    @close="isModalOpen = false"
                >
                </ModalComponent>
            </section>
            <div class="row align-items-end mt-5 hide-on-mobile-flex">
                <template v-for="(img, index) in buttonsSecondLine" :key="img">
                    <div class="col-12 col-md-3 mobile-mt-2">
                        <a class="d-block" v-if="img.url" :href="img.url">
                            <img :src="img.imagem" style="max-width: 100%; min-width: 100%;" />
                        </a>
                        <img v-else :src="img.imagem" style="max-width: 100%; min-width: 100%;" />
                    </div>
                </template>
            </div>
            <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile mt-5">
                <SplideSlide v-for="slide in buttonsSecondLine" :key="slide">
                    <a :href="slide.url">
                        <img :src="slide.imagem" style="max-width: 100%" />
                    </a>
                </SplideSlide>
            </Splide>
            <section class="mt-5">
                <h2 class="section-title">Nossos destaques</h2>
                <div class="row">
                    <div v-for="grupo in highligthGroups.slice(0, 14)" class="product-group-card show-on-mobile-flex">
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <img :src="grupo.imagem" />
                        </router-link>
                        <router-link class="flex-auto" :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <h5 class="my-2">{{grupo.nome}}</h5>
                        </router-link>
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <div class="product-detail">
                                <small>A partir de:</small>
                                <span v-if="grupo.id == 290" class="price">{{ formatCurrency(150) }} </span>
                                <span v-else-if="grupo.id == 289" class="price">{{ formatCurrency(418) }} </span>
                                <span v-else class="price">{{ formatCurrency(grupo.preco) }} </span>
                            </div>
                        </router-link>
                    </div>
                    <div v-for="grupo in highligthGroups" class="product-group-card hide-on-mobile-flex">
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <img :src="grupo.imagem" />
                        </router-link>
                        <router-link class="flex-auto" :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <h5 class="my-2">{{grupo.nome}}</h5>
                        </router-link>
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                            <div class="product-detail">
                                <small>A partir de:</small>
                                <span v-if="grupo.id == 290" class="price">{{ formatCurrency(150) }} </span>
                                <span v-else-if="grupo.id == 289" class="price">{{ formatCurrency(418) }} </span>
                                <span v-else class="price">{{ formatCurrency(grupo.preco) }} </span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <router-link :to="{ name: 'produtos'}" class="btn btn-primary mt-5" style="width: 300px; margin: 0 auto; display: block;">Todos os produtos</router-link>
            </section>
        </main>
        <Footer />
    </div>
</template>

<script>
    // If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import ModalComponent from '@/components/ModalComponent.vue'
    import Footer from '@/components/Footer.vue'
    import {ConfigDBMixin} from '@/database/config.js'
    import { useHead } from '@vueuse/head';

    export default {
        name: 'App',
        mixins: [ConfigDBMixin],
        components: {
            Splide,
            SplideSlide,
            ModalComponent,
            Footer,
            useHead
        },
        data() {
            return {
                selectedProduct: null,
                isLoading: true,
                isModalOpen: false,
                buttonsFirstLine:[],
                buttonsSecondLine:[],
                imageslides: [],
                bannerMobile: [],
                highligthCats: [],
                highligthGroups: [],
                highligthProducts: [],
                botoesHome: [
                    'https://padraocolor.com.br/img/bt-mobile-3.png',
                    'https://padraocolor.com.br/img/bt-mobile1.png',
                    'https://padraocolor.com.br/img/bt-mobile-2.png'
                ],
            }
        },
        computed: {
            
        },
        methods: {
            openModal(produto) {
                this.selectedProduct = produto;
                this.isModalOpen = true;
            },
            formatCurrency(valor) {
                const valorFormatado = valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
                return valorFormatado
            },
            async getConfig() {
                const data = await this.getStoreConfig();
                //const filteredMenuItems = data.filter(item => item.tipo === "Menu");
                const filteredBannerItems = data.filter(item => item.tipo === "Banner");
                const filteredButtonsFirstLineItems = data.filter(item => item.tipo === "Botões - Linha 1");
                const filteredButtonsSecondLineItems = data.filter(item => item.tipo === "Botões - Linha 2");
                const filteredHighLigthCatsItems = data.filter(item => item.tipo === "Categorias em Destaque");
                const filteredHighLigthGroupsItems = data.filter(item => item.tipo === "Nossos Destaques");
                const filteredHighLigthProductsItems = data.filter(item => item.tipo === "Produtos mais Vendidos");
                this.imageslides.push(filteredBannerItems)
                this.bannerMobile.push(filteredBannerItems)
                this.buttonsFirstLine.push(...filteredButtonsFirstLineItems)
                this.buttonsSecondLine.push(...filteredButtonsSecondLineItems)
                this.highligthCats.push(...filteredHighLigthCatsItems)
                this.highligthGroups.push(...filteredHighLigthGroupsItems)
                this.highligthProducts.push(...filteredHighLigthProductsItems)
                //hack to avoid empty value for the nome field
                this.highligthCats.forEach(element => {
                    if (element.nome == '') {
                        element.nome = 'teste'
                    }
                });
                // console.log("planos", data)
                // console.log("buttons 1 filter", filteredButtonsFirstLineItems)
                // console.log("buttons 1", this.buttonsFirstLine)
                // console.log("Banner", this.imageslides)
                // console.log("Categorias Destaque", this.highligthCats)
                // console.log("Nossos Destaque", this.highligthGroups)
                // console.log("Produtos mais Vendidos", this.highligthProducts)
                this.isLoading = false;
            }
        },
        mounted(){
            this.getConfig();
            useHead({
                title: "Padrão Color - Gráfica e Comunicação Visual",
                meta: [
                {
                    name: "description",
                    content: "A Padrão Color é a maior gráfica do Rio de Janeiro. Explore cartões de visita, banners, adesivos, fachadas e muito mais. Confira nossas soluções gráficas!"
                }
                ]
            });
        },
    }
</script>
<style lang="scss">
    @import '@splidejs/vue-splide/css';
    @import "@/assets/styles/cards.scss";

    .btn.btn-primary {
        background: #FF463A;
        color: #fff;
        border-radius: 50px;
        width: 100%;
        border: none;
        padding: 7px 15px;
        margin: 0;   
    }
    img {
        max-width: 100%;
        height: auto;
    }
    section {
        h2.section-title {
            text-align: left;
        }
    }
    .container-full {
        max-width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
        img {
            max-width: 100%;
        }
    }

    .carousel__slide {
    padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
    }
    .grupo-produtos {
        margin-top: 100px;
        .grupo-produtos-col {
            width: calc(100%/5);
            padding: 0 1.5%;
        }
    }
    .product-group-card {
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .product-group-card {
        a:hover {
            color: #FF463A;
            text-decoration: none;
        }
    }
    
    .col-buybutton {
        width: calc(60% - 10px);
        margin-left: 10px;
    }
    .col-number {
        width: 40%;
        .vue-number-input {
            height: 38px;
        }
        .vue-number-input__input {
            height: 38px;
        }
        .vue-number-input {
            border-radius: 30px;
            border: 1px solid #ccc;
        }
        .vue-number-input__button {
            width: 1rem !important;
        }
        .vue-number-input__button::before {
            width: 8px !important;
            background-color: #FF463A !important;
        }
        .vue-number-input__button::after {
            height: 8px !important;
            background-color: #FF463A !important;
        }
        .vue-number-input--small.vue-number-input--controls > input {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
            text-align: center;
        }
    }
    .hide-on-mobile {
        display: block !important;
    }
    .show-on-mobile, .show-on-mobile-flex {
        display: none !important;
    }
    .hide-buttons-on-mobile {
        display: flex !important;
    }
    .hide-on-mobile-flex {
        display: flex;
    }
    
    @media screen and (max-width: 900px) {
        .header-links {
            align-items: center;
            line-height: 15px;
        }
        
        .hide-on-mobile-flex {
            display: flex;
        }
        
    }

    @media screen and (max-width: 790px) {
        .show-on-mobile-flex {
        display: flex !important;
    }
        
    }
    @media screen and (max-width: 490px) {
        .mobile-mt-2 {
            margin-top: 2rem;
        }
        .hide-on-mobile-flex {
            display: none !important;
        }
        .hide-on-mobile, .hide-buttons-on-mobile{
            display: none !important;
        }
        .show-on-mobile {
            display: block !important;
        }
        
        .grupo-produtos {
            justify-content: center;
            margin-top: 50px;
            .grupo-produtos-col {
                width: 50%;
                padding: 10px;
            }
            .grupo-produtos-col:last-child {
                //display: none;
            }
        }
    }
</style>