import axios from "axios"
import { environment } from '@/stores/environment';

export const UsersApiMixin = {
  data() {
    return {
      // baseUrl: 'http://137.184.148.25/api', // Base URL for the API
      baseUrl: `${environment.API_URL}`, // Base URL for the API
    };
  },
  methods: {
    async axiosInstance() {
      const token = `${environment.API_TOKEN}`;
      
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },
    async axiosUserInstance(token) {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async axiosInstanceApi() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'https://padraocolor.com.br/api/v2', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async registerUser(data) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.post(`${environment.API_URL}/site/cadastrarcliente`, data);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error register user", error);
        throw new Error(JSON.stringify(error.response.data) || 'Não foi possível realizar o cadastro');
      }
    },
    
    async getUser(token) {
      try {
        const instance = await this.axiosUserInstance(token);
        const response = await instance.get(`/site/cliente`);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error fetching user by ID", error);
      }
    },

    async getAllPurchases(token, startDate, endDate) {
      try {
        const instance = await this.axiosUserInstance(token);
        const response = await instance.get(`/site/pedidos?data_inicial=${startDate}&data_final=${endDate}&Pag=1&Reg=100`);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error fetching user by ID", error);
      }
    },

    async getPurchases(token,id) {
      try {
        const instance = await this.axiosUserInstance(token);
        const response = await instance.get(`/site/pedidos?pedido${id}`);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error fetching user by ID", error);
      }
    },

    async checkProductStatus(token,id) {
      try {
        const instance = await this.axiosUserInstance(token);
        const response = await instance.post(
          `/site/consultarstatusproduto`,
          { idProduto: id },
          { headers: { 'X-HTTP-Method-Override': 'GET' } }
        );
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error fetching user by ID", error);
      }
    },

    async authenticateUser(credentials) {
      try {
        const response = await axios.post(`${this.baseUrl}/site/user/login`, credentials);
        const access_token  = response.data.api_token;
        let loggedUserData = {}
        loggedUserData = response.data.login
        
        localStorage.setItem('user_token', 'Bearer '+ access_token); // Save the token to localStorage
        localStorage.setItem('user', loggedUserData); 
        localStorage.setItem('userId', response.data.id);

        environment.API_TOKEN = `Bearer ${access_token}`
        
        // console.log("User authenticated successfully.", access_token);
        return access_token;
      } catch (error) {
        console.error("Error authenticating user", error);
        throw new Error(error.response.data ||
           'Não foi possível realizar o login');
      }
    },

    async updateUser(data) {
      try {
        const response = await axios.post(`https://padraocolor.com.br/api/v2/mail/token-update-user`, data);
        
        return response

      } catch (error) {
        console.error("Error Update User", error);
        throw new Error(error.response.data ||
          error.response ||
          error ||
          'Não foi possível atualizar os dados');
      }
    },
    async confirmUpdateUser(data) {
      try {
        // console.log("TOKEN", data)
        const instance = await this.axiosUserInstance(data.token);
        const response = await instance.post(`${this.baseUrl}/site/atualizacliente`, data);
        //const response = await axios.post(`http://doispontozero.padraocolor.com.br/api/mail/confirm-token-update-user`, data);
        
        return response

      } catch (error) {
        console.error("Error Confirm Update User", error);
      }
    },

    async forgotPwd(data) {
      try {
        const response = await axios.post(`https://padraocolor.com.br/api/v2/mail/token-forgot-pwd`, data);
        
        return response

      } catch (error) {
        console.error("Error Update User", error);
        throw new Error(error.response.data ||
          error.response ||
          error ||
          'Não foi possível atualizar os dados');
      }
    },

    async confirmForgotPwd(data) {
      try {
        const instance = await this.axiosUserInstance();
        const response = await instance.post(`${this.baseUrl}/site/recuperarsenha`, data);
        //const response = await axios.post(`http://doispontozero.padraocolor.com.br/api/mail/confirm-token-update-user`, data);
        
        return response

      } catch (error) {
        console.error("Error Confirm Update User", error);
      }
    },

    async forgotEmail(data) {
      try {
        // console.log("LOG", data)
        const instance = await this.axiosUserInstance();
        const response = await instance.post(`${this.baseUrl}/site/recuperaremail`, data);
        
        return response

      } catch (error) {
        console.error("Error Update User", error);
        throw new Error(error.response.data ||
          error.response ||
          error ||
          'Não foi possível atualizar os dados');
      }
    },

    async orderFinish(data) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.post(`site/gerarpedido`, data);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error finishing Order", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async sendCancelOrder(data) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.post(`site/cancelarcompra`, data);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error cancel Order", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async sendCancelOrderItem(data) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.post(`site/cancelarpedido`, data);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error cancel Order Item", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async resendArt(data) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.post(`site/reenviararte`, data);
        return response.data; // This should be a single user object
      } catch (error) {
        console.error("Error resend art", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async getAllFavorites(userId) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.get(`/favorite/${userId}`);
        // console.log("Favoritos:", response.data);
        return response.data
      } catch (error) {
        console.error("Error Favorite", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async deleteFavorite(id) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.delete(`/favorite/${id}`);
        // console.log("Favoritos:", response.data);
        return response.data
      } catch (error) {
        console.error("Error Favorite", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Unknown error occurred');
      }
    },

    async getServerTime() {
      try {
        const response = await axios.get(`https://padraocolor.com.br/api/v2/general/get-time`);
        return response.data
        } catch (error) {
          throw new Error(error.response?.data?.message ||
            error.response?.data?.error ||
             'Unknown error occurred');
        }
    }

  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
