<template>
    <div class="top-header">
        <a v-if="topHeader.image" :href="topHeader.url">
            <img style="width: 100%;" :src="topHeader.image" />
        </a>
        <div class="row container my-4" style="margin: 0 auto;">
            <div class="col-md-6 d-flex align-items-center">
                <img class="icons-nav mr-2" src="@/assets/icons/projetos-especiais.png" />
                <router-link :to="{ name: 'specialprojects'}">
                    <span style="color: #000;">Projetos Especiais: Faça seu produto sob medida</span>
                </router-link>
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
                <span class="mr-2">Siga nossas redes: </span>
                <a href="https://www.instagram.com/padraocolor/" target="_blank">
                    <img class="icons-top-header  mr-2" src="@/assets/icons/instagram.png" />
                </a>
                <a href="https://www.facebook.com/padraocolor/?locale=pt_BR" target="_blank">
                    <img class="icons-top-header mr-2" src="@/assets/icons/facebook.png" />
                </a>
                <a href="https://www.youtube.com/channel/UCUm-Oi-n1m0kqO6-VKATmdw" target="_blank">
                    <img class="icons-top-header mr-2" src="@/assets/icons/youtube.png" />
                </a>
                <a href="https://www.linkedin.com/company/padraocolor" target="_blank">
                    <svg class="icons-top-header mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                </a>
                <a href="https://www.tiktok.com/@padrao.color?is_from_webapp=1&sender_device=pc" target="_blank">
                    <svg class="icons-top-header" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {ConfigDBMixin} from '@/database/config.js'
    export default {
        mixins: [ConfigDBMixin],
        components: {
        },
        data() {
            return {
                topHeader:{
                    image:'',
                    url:''
                }
            }
        },
        methods: {
            async getConfig() {
                const data = await this.getStoreConfig();
                const filteredBannerItems = data.filter(item => item.tipo === "TopHeader");
                this.topHeader.image= filteredBannerItems[0].imagem
                this.topHeader.url= filteredBannerItems[0].url
                //console.log("config", data)
            }
        },
        mounted() {
            this.getConfig()
        },
    }
</script>
<style lang="scss" scoped>
    svg.icons-top-header {
        width: 15px;
        fill: #FF463A;
    }
    .top-header {
        border-bottom: 1px solid #D9D9D9;
    }
</style>