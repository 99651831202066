import axios from "axios"
import { environment } from '@/stores/environment';

export const GroupDBMixin = {
  data() {
    return {
      baseUrl: `${environment.API_URL}/site`, // Base URL for the API
      jadlogUrl: 'https://www.jadlog.com.br/embarcador/api', // Base URL for the API
    };
  },
  methods: {
    async axiosInstanceGroup() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = `${environment.API_TOKEN}`;
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },
    async axiosInstanceJadlog() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOjcwNjU3LCJkdCI6IjIwMjEwNzA4In0.LwHGzKEaStve2QlPKinoO95c9ZdgyiFcQtICXaIErZo';
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.jadlogUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },
    async getAllGroups() {
      try {
        const instance = await this.axiosInstanceGroup();
        const response = await instance.get(`/grupos`);
        // console.log("Todos os Grupos.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getGroup(id) {
      try {
        const instance = await this.axiosInstanceGroup();
        const token = `${environment.API_TOKEN}`;
        const response = await axios.get(`${environment.API_URL}/site/grupos?id=${id}`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        // console.log("Dados do Grupo.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getGroupsByNameAndTag(name) {
      try {
        const instance = await this.axiosInstanceGroup();
        const response = await instance.get(`/grupos`, {
          params: {
            grupo: name,
            tag: name
          }
        });
        // console.log("getGroupByName - Dados dos Grupos.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getGroupProducts(groupId) {
      try {
        const instance = await this.axiosInstance();
        const token = `${environment.API_TOKEN}`;
        const response = await axios.get(`${environment.API_URL}/site/produtos/?id_grupo=${groupId}`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        // console.log("Produtos do Grupo.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getShippingOptions(data) {
      try {
        const instance = await this.axiosInstanceJadlog();
        const response = await instance.post(`/frete/valor`, data);
        // console.log("Resposta Jadlog.", response.data);
      } catch (error) {
        console.error("Error inserting Digitadora", error);
      }
    }
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
