<template>
    <CookiePolicy v-if="showCookiePolicy" />
    <div class="mt-5 hide-on-mobile">
        <router-link class="cta" :to="{ name: 'specialprojects'}">
            <img src="@/assets/images/chamada-footer.png">
        </router-link>
    </div>
    <div class="mt-5 show-on-mobile">
        <img src="@/assets/images/chamada-footer-mobile.png">
    </div>
    <footer class="container py-5">
        <div class="footer-col">
            <h5>Institucional</h5>
            <ul>
                <li>
                    <router-link :to="{ name: 'aboutus'}">
                        Sobre Nós
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'policies'}">
                        Política de Privacidade
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'ourstores'}">
                        Nossas Lojas
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'contact'}">
                        Contatos
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'trabalheconosco'}">
                        Faça Parte da Nossa Equipe
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Informações</h5>
            <ul>
                <li>
                    <router-link :to="{ name: 'helpcenter', params: { tab: 1 }}">
                        Central de Ajuda
                    </router-link>
                </li>
                <li>
                    <a href="https://blog.padraocolor.com.br/?cat=7" target="_blank">Dicas e Tutoriais</a>
                </li>
                <li>
                    <router-link :to="{ name: 'pickuppoint'}">
                        Pontos de retirada
                    </router-link>
                </li>
                <li>
                    <a href="https://blog.padraocolor.com.br" target="_blank">Blog</a>
                </li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Comercial</h5>
            <ul>
                <li>
                    <router-link :to="{ name: 'vendascorporativas'}">
                        Venda Corporativa
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'sejarevendedor'}">
                        Seja um Revendedor
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'resellercategories'}">
                        Beneficios de nossas categorias
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Contato</h5>
            <ul>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/phone.png" />(21)3668-1550</li>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/mail.png" /><span style="word-break: break-all;">sac@padraocolor.com.br</span></li>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/mapa.png" />
                    <span>
                        Rua Nicolau Cheven, Nº 255 / São João de Meriti – RJ<br>
                        Horário de Atendimento:<br>
                        Seg a sex - 8h as 18h
                    </span>
                </li>
            </ul>
        </div>
    </footer>
    <div class="bottom-footer">
        <div class="container row justify-content-between">
            <div class="copyright">
                <span>Padrão Color - Todos os Direitos Reservados</span>
                <br>
                <small>CNPJ: 14.572.530/0001-19 - Razão Social: APEL GRAFICA E EDITORA LTDA</small>
            </div>
            <div class="text-align-right d-flex align-items-center">
                <img class="social-icons mr-2" src="@/assets/icons/youtube.png" />
                <img class="social-icons mr-2" src="@/assets/icons/facebook.png" />
                <img class="social-icons mr-2" src="@/assets/icons/instagram.png" />
                <a href="#">
                    <svg class="social-icons mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                </a>
                <a href="#">
                    <svg class="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import CookiePolicy from './CookiePolicy.vue'
    export default {
        components: {
            CookiePolicy
        },
        data() {
            return {
                showCookiePolicy: true
            }
        },
        mounted() {
            const cookiePolicy = localStorage.getItem('cPol');
            if (cookiePolicy) {
                // console.log(cookiePolicy)
                this.showCookiePolicy = false;
            } else {
                // console.log("Nao Tem")
            }
            
        },
    }
</script>
<style lang="scss" scoped>
    .copyright {
        text-align: left;
        small {
            font-size:0.8rem;
        }
    }
    .cta {
        display: block;
        background: linear-gradient(45deg, #92000a, #db1121);
        text-align: center;
    }
    footer {
        display: flex !important;
        justify-content: center;
    }
    svg.social-icons {
        width: 15px;
        fill: #FF463A;
    }
    .footer-col {
        width: calc(90%/4);
        padding: 15px 10px 25px;
        text-align: left;
        a {
            color: #2c3e50;
        }
        h5 {
            font-weight: 400;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }
    }
    .bottom-footer {
        display: flex;
        background: #FAFAFA;
        padding: 20px 10px;
        justify-content: center;
    }
    .hide-on-mobile {
        display: block !important;
    }
    .show-on-mobile {
        display: none !important;
    }
    @media screen and (max-width: 900px) {
        footer {
            flex-wrap: wrap;
        }
        .footer-col {
            width: 50%;
            padding: 15px 0;
            text-align: left;
        }
    }
    @media screen and (max-width: 490px) {
        .hide-on-mobile {
            display: block !important;
        }
        .show-on-mobile {
            display: none !important;
        }
        footer {
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .footer-col {
            width: 100%;
            padding: 15px 0;
            text-align: center;
        }
        .footer-col:last-child {
            width: 100%;
            text-align:left;
        }
    }
</style>