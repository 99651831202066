<template>
  <div class="megamenu-wrapper">
    <div v-if="isLoading" class="loading-menu">
      <img src="@/assets/icons/loading.svg" alt="Carregando" />
    </div>
    <img class="close-menu" @click="hideMegaMenuMobile" src="@/assets/icons/close.svg" alt="Padão Color" />
    <div class="main-categories" v-if="!showSubcategories">
      <div
        v-for="category in categories"
        :key="category.id"
        @click="changeMainCategoryMobile(category.id)"
      >
        <div class="label">
          <div>
              {{ category.nome }}
          </div>
          <div>
              <img :src="chevronRight" />
          </div>
          
        </div>
      </div>
    </div>
    <div class="sub-categories" v-if="showSubcategories">
      <div class="sub-categories-header" @click="backToCategories">
        <img :src="chevronRight" />
        Todas as categorias
      </div>
      <p></p>
      <div v-for="group in categoryGroups" :key="group.id" class="sub-category">
        <router-link @click="hideMegaMenuMobile" :to="{ name: 'produto', params: { groupId: group.id, groupName: group.nome } }">    
          <!-- <div class="icon"><img :src="group.imagem" /></div> -->
          <div class="label">
            {{ group.nome }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="close-outside" @click="hideMegaMenuMobile">
      
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus";
import {CategoryDBMixin} from '@/database/category.js'
import chevronRight from '@/assets/icons/chevron-right.svg'

export default {
  name: "MegaMenuMobile",
  mixins: [CategoryDBMixin],
  data() {
    return {
      chevronRight,
      categories: [],
      categoryGroups: [],
      showSubcategories: false,
      activeMainCategory: "",
      lastX: 0,
      lastY: 0,
      direction: "",
      throttle: false,
      isLoading: true,
    };
  },
  methods: {
    backToCategories () {
      this.showSubcategories = false;
    },
    async getCategories() {
        const data = await this.getAllCategories();
        // console.log("MENU cat", data)
        this.categories = data;
        this.isLoading = false;
        //this.activeMainCategory = this.categories[0].id
    },
    async loadCategoryGroups(name) {
        this.isLoading = true;
        let data = []
        data = await this.getCategoryGroups(name);
        this.categoryGroups = data[0].grupos
        // console.log("Grupos carregados", this.categoryGroups)
        this.isLoading = false;
    },
    hideMegaMenuMobile() {
      eventBus.emit("hide-mega-menu-mobile");
    },
    isActive(key) {
      return this.activeMainCategory === key;
    },
    mouseDirection(e) {
      if (!this.throttle) {
        this.throttle = true;
        let theta = Math.abs(
          (180 * Math.atan2(e.pageY - this.lastY, e.pageX - this.lastX)) /
            Math.PI
        );
        this.direction = theta > 75 ? "vertical" : "horizontal";
        this.lastX = e.pageX;
        this.lastY = e.pageY;
        setTimeout(() => {
          this.throttle = false;
        }, 50);
      }
    },
    changeMainCategoryMobile(index) {
      // if (this.direction === "vertical") {
      //   this.activeMainCategory = index;
      // }
      // //console.log("teste", index)
      this.showSubcategories = true
      this.loadCategoryGroups(index)
    },
    handleClickOutside(event) {
      if (!event.target.closest('.megamenu-wrapper')) {
        this.hideMegaMenuMobile();
      }
    },
  },
  mounted() {
    // window.addEventListener("mousemove", this.mouseDirection);
    //document.addEventListener('click', this.handleClickOutside);
    this.getCategories();
  },
  beforeDestroy() {
    // window.removeEventListener("mousemove", this.mouseDirection);
    //document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.loading-menu {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff8f;
  img {
    width: 100px;
  }
}
.close-menu {
  position: absolute;
  right: 15px;
  width: 35px;
  background: #efefef;
  border-radius: 10px;
  border: 1px solid #a4a4a4;
}
.megamenu-wrapper {
    border: 1px solid #a4a4a4;
    box-shadow: 4px 15px 20px -21px;
    margin: auto;
    background: transparent;
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 9999;
    min-height: 100vh;
    max-width: 1400px;
    top: 0;
    display: flex;
}

.main-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  background: #efefef;
  padding: 20px 0 0 20px;
  width: 80%;
  .label {
    display: flex;
    justify-content: space-between;
    img {
      width: 24px;
    }
  }
}

.close-outside {
  height: 100vh;
  width: 20%;
}

.main-category {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0 0px 0 30px;
  .label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 20px;
  }
}
.main-category:hover {
  background: #FF463A;
  color: #fff;
}
.main-category {
  .label > div{
    font-weight: 500;
  }
}

.main-category:not(:last-of-type) {
  // border-bottom: 1px solid #c2c2c2;
}

.main-category .icon {
  margin-right: 15px;
}

.main-category.active {
  background: #ff4341;
  color: #fff;
  font-weight: 500;
  label{
    font-weight: 500;
  }
}

.sub-categories {
  background: #fff;
  padding: 25px 10px;
  text-align: left;
  width: 80%;
}
.sub-categories-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  img {
    width: 25px;
    transform: rotate(180deg);
  }
}

.sub-category {
  cursor: pointer;
  a {
    color: #000;
  }
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.sub-category:hover {
  background: #dedede;
}

.sub-category .label {
  margin-top: 15px;
  font-weight: 400;
}
</style>