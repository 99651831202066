<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal">
        <header class="modal-header">
        <!-- {{attachType}} -->
          <div name="header">
            <h3 v-if="product.nome">{{ product.nome }}</h3>
            <h3 v-else>{{ product.grupo }}</h3>
          </div>
          <button class="close-button" @click="closeModal">&times;</button>
        </header>
  
        <section class="modal-body">
          <div>
            <img :src="product.imagem_1" alt="" :style="{ backgroundColor: selectedLedColorHex }">
            
          </div>
          <div>
            <!-- <p class="modal-price">{{formatCurrency(selectedPrice)}}</p> -->
            <p class="modal-price">{{formatCurrency(skuPrice)}}</p>
            <ul>
              <li v-for="(variacao, index) in product.variacoes"><label for="">{{index}}:</label> {{variacao}}</li>
            </ul>
            <div class="product-sizes" v-if="Object.keys(groupSizes).length > 0">
              <label>Quantidades:<br><small>(por tamanho)</small></label>
              <div class="product-sizes-container">
                <div class="input-number-container" v-for="(value, attribute) in groupSizes">
                  <label class="label-input-number">{{attribute}}:</label>
                  <vue-number-input size="small" v-model="selectedSizes[attribute]" :min="0" inline center controls></vue-number-input>
                </div>
              </div>
            </div>
            <div v-else class="qty-input">
              <div class="input-number-container">
                <label class="d-block" for="">Quantidade: <small v-if="minQty > 1">(Mínimo {{minQty}})</small></label>
                <vue-number-input style="width:100%" v-model="selectedQty" size="small" :min="minQty" :max="maxQty" inline center controls></vue-number-input>  
              </div>
            </div>
            <div v-if="isLedCor || hasLedSimulation" class="product-led-color">
              <label>Cor do LED:</label>
              <div class="led-colors">
                <div v-for="item in ledColors" :key="item.name">
                  <input :id="item.name" class="mr-2" type="radio" name="led-color" v-model="selectedLedColor" :value="item.name">
                  <label class="led-color-radio" :class="{ 'selected-led-color': $data.selectedLedColor === item.name }" :for="item.name" :style="{ backgroundColor: item.hexa }">
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="product-production-type px-3">
                <h5>Prazo de liberação</h5>
                  <div style="display: flex;align-items: center;">
                    <input class="mr-2" type="radio" name="prazo2" v-model="selectedProductionTime" value="economico">
                    <label for="economico" style="margin:0">
                      <span>Econômico</span>
                      <small>({{ product.prazo.economico }} {{ product.prazo.economico === 1 ? 'dia útil' : 'dias úteis' }})</small>
                    </label>
                  </div>
                  <div style="display: flex;align-items: center;">
                    <input class="mr-2" type="radio" name="prazo2" v-model="selectedProductionTime" value="expresso">
                    <label for="expresso" style="margin:0">
                      <span>Expresso</span>
                      <small>({{ product.prazo.expresso }} {{ product.prazo.expresso === 1 ? 'dia útil' : 'dias úteis' }})</small>
                    </label>
                  </div>
              </div>
              <div v-if="product.customizacao.tipo == 1" class="product-file-handling px-3">
                <h5>Tratamento de Arquivo <img src="@/assets/icons/info.svg" alt="Padrão Color" /></h5>
                <div v-for="handle in fileHandle" :key="handle.name" style="display: flex;align-items: center;">
                  <input class="mr-2" type="radio" name="tratamento" v-model="selectedFileHandle" :value="handle.name">
                  <label style="margin:0" :for="handle.name">{{handle.name}} ({{ formatCurrency(handle.price) }}{{ handle.price === 0 ? ' - Grátis' : '' }})</label>
                </div>
              </div>
            </div>
            <div v-if="acabamentos.length" class="product-finishes">
              <h5>Acabamentos extras</h5>
              <div class="product-finishes-container">
                <div class="input-number-container input-finishes" v-for="(value, attribute) in acabamentos" :key="attribute">
                  <label class="label-input-number label-finishes">
                    {{value['nome']}}: <img class="pointer" src="@/assets/icons/info.svg" alt="Padrão Color" @click="toggleFinishDescription(attribute)" />
                    <div class="help-balloon" v-if="isFinishDescriptionVisible(attribute)">
                      <div class="help-balloon-close" @click="toggleFinishDescription(attribute)">
                        x
                      </div>
                      <p>{{ value['descricao'] }}</p>
                    </div>
                  </label>
                  <vue-number-input v-if="value['informa_qtde'] === 0" size="small" @update:model-value="onUpdateFinishes2($event, value)" :min="0" :max="1" inline center controls></vue-number-input>
                  <vue-number-input v-else size="small" @update:model-value="onUpdateFinishes2($event, value)" :min="0" inline center controls></vue-number-input>
                  <!-- <small v-if="value['prazo'] > 0">+ {{value['prazo']}} dia<span v-if="value['prazo'] > 1">s</span> para produzir</small> -->
                </div>
              </div>
              <div class="escolhe-faca" v-if="escolheFaca">
                <div class="escolhe-faca-content">
                  <button class="button button-secondary" @click="updataFaca(1)">
                    Já tenho minha faca de corte (+ R$40)
                  </button>
                  <button class="button button-secondary mt-2" @click="updataFaca(2)">
                    Preciso que a produzam minha faca (+ R$210)
                  </button>
                </div>
              </div>
            </div>
            <div v-if="product.customizacao.tipo == 1" class="product-art-type">
              <div class="product-art-type-input-container">
                <label>Arte:</label>
                <select v-model="selectedArtType" >
                  <option v-for="art in artType" :key="art.id" :value="art.id">{{ art.name }} {{ art.id === 2 ? '(' + productArtCreationPrice + ')' : '' }}</option>
                </select>
              </div>
              <div class="product-art-type-attach">
                <div v-if="selectedArtType == 1 && attachType[0] == 'Arquivo Único'">
                  <!-- <div>
                    <label for="">Nome da arte:<span class="required-field">*</span></label>
                    <input type="text" name="" :class="{'input-error': validationSendArtErrors.name}" v-model="sendArtForm.name">
                    <p v-if="validationSendArtErrors.name" class="error">{{ validationSendArtErrors.name }}</p>
                  </div> -->
                  
                  <div v-if="!attachMutipleFileSelected">
                    <label>Arquivo único:</label>
                    <DropFile
                      attachType="Arquivo Único"
                      @file-uploaded="handleFileUploaded"
                      @remove-file="removeUploadedFile"
                      />
                  </div>
                  <template v-if="attachType.length > 1 && !attachSingleFileSelected">
                    <p
                      v-if="!attachMutipleFileSelected"
                      style="
                      text-align: center;
                      margin: 20px 0;
                      color: #ff4341;
                      font-size: 1.5rem;
                      font-weight: 600;">
                      ou
                    </p>
                    <div class="attach-multiple">
                      <div style="width:calc(50% - 20px); margin: 15px 10px;" v-for="(item, index) in attachType.slice(1)" :key="index">
                        <label>{{item}}:</label>
                        <DropFile
                          :attachType="item"
                          @file-uploaded="handleFileUploaded"
                          @remove-file="removeUploadedFile" />
                      </div>
                    </div>
                  </template>
                  <div class="row mb-4">
                    <div class="col-sm-12">
                      <label for="">
                        <input type="checkbox" name="aceite-termos" v-model="sendArtForm.accepted">
                        Concordo que a arte enviada é de minha responsabilidade e está de acordo com os <router-link :to="{ name: 'artPolices'}" target="_blank">termos de envio de arte</router-link>.
                      </label>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="">Observação:</label>
                    <textarea 
                      v-model="sendArtForm.desc"
                      @input="updateCharacterCount"
                      :maxlength="maxCharacters"
                    >
                    </textarea>
                    <small>{{ characterCount }} de {{ maxCharacters }} caracteres</small>
                  </div>
                </div>
                <div v-if="selectedArtType == 2">
                  <div class="row mb-4">
                    <div class="col-12">
                      <label for="">Seu nome:<span class="required-field">*</span></label>
                      <input type="text" class="form-control" :class="{'input-error': validationArtCreationErrors.name}" v-model="artCreationForm.name" placeholder="digite seu nome e sobrenome" >
                      <p v-if="validationArtCreationErrors.name" class="error">{{ validationArtCreationErrors.name }}</p>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-sm-12 col-md-6">
                      <label for="">Email:<span class="required-field">*</span></label>
                      <input type="text" class="form-control" :class="{'input-error': validationArtCreationErrors.email}" v-model="artCreationForm.email" placeholder="email@email.com" >
                      <p v-if="validationArtCreationErrors.email" class="error">{{ validationArtCreationErrors.email }}</p>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="">Telefone (whatsapp):<span class="required-field">*</span></label>
                      <input v-maska="maskaPhone" type="text" class="form-control" :class="{'input-error': validationArtCreationErrors.phone}" v-model="artCreationForm.phone" placeholder="(xx) 99999-9999" >
                      <p v-if="validationArtCreationErrors.phone" class="error">{{ validationArtCreationErrors.phone }}</p>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-sm-12 col-md-6">
                      <label for="">Arte de referência:</label>
                      <DropFile
                        attachType="Arte Referencia"
                        @file-uploaded="handleFileUploaded"
                        @remove-file="removeUploadedFile"
                      />
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="">Seu logotipo:</label>
                      <DropFile
                        attachType="logotipo"
                        @file-uploaded="handleFileUploaded"
                        @remove-file="removeUploadedFile"
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-sm-12">
                      <label for="">
                        <input type="checkbox" name="aceite-termos" v-model="artCreationForm.accepted">
                        Declaro que aceito <router-link :to="{ name: 'artCreation'}" target="_blank">todos os termos</router-link> para a solicitação de criação de arte
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div v-if="sendArtMsg" class="warning-msg">
          <small :class="{ 'disabled': !selectedId }">{{sendArtMsg}}</small>
        </div>
        <footer class="modal-footer">
          <div class="total-price">
            <span>Total:</span>
            <p v-if="hasSizes.has">
              
              <span v-if="selectedArtType === 2 && selectedFileHandle === 'Profissional'">
                {{ formatCurrency(calculateCostWithSizes(selectedArtType, 'Profissional')) }}
              </span>
              <span v-else-if="selectedArtType === 2">
                {{ formatCurrency(calculateCostWithSizes(selectedArtType)) }}
              </span>
              <span v-else-if="selectedFileHandle === 'Profissional'">
                {{ formatCurrency(calculateCostWithSizes(1, 'Profissional')) }}C
              </span>
              <span v-else-if="selectedArtType === 1 && selectedFileHandle === 'Padrão'">
                {{ formatCurrency(calculateCostWithSizes(selectedArtType, 'Padrão')) }}
              </span>
              
            </p>
            <p v-else>
              <span v-if="selectedArtType === 2 && selectedFileHandle === 'Profissional'">
                {{ formatCurrency(calculateCost(selectedArtType, 'Profissional')) }}
              </span>
              <span v-else-if="selectedArtType === 2">
                {{ formatCurrency(calculateCost(selectedArtType)) }}
              </span>
              <span v-else-if="selectedFileHandle === 'Profissional'">
                {{ formatCurrency(calculateCost(1, 'Profissional')) }}
              </span>
              <span v-else-if="selectedArtType === 1 && selectedFileHandle === 'Padrão'">
                {{ formatCurrency(calculateCost(selectedArtType, 'Padrão')) }}
              </span>
            </p>
          </div>
          <div style="display: flex; gap: 10px;" class="button-container">
            <button class="button button-primary mt-3" @click="addToCart">Adicionar ao carrinho</button>
          </div>
        </footer>
      </div>
    </div>
</template>
  
<script>

  import DropFile from "@/components/DropFile.vue";
  import {UsersApiMixin} from '@/database/user.js'
  import { mapActions } from 'pinia';
  import { useCartStore } from '../stores/cartStore';
  import { vMaska } from "maska/vue";

  export default {
    components: {
      //ButtonComponent,
      DropFile
    },
    mixins: [UsersApiMixin],
    directives: { maska: vMaska },
    name: 'ModalComponent',
    emits: ['close', 'submit', 'customEvent'],
    props: {
        header: String,
        product: Object
    },
    data() {
        return {
          maxCharacters:500,
          characterCount: 0,
          cartData: {
            productId: 0,
            productName: "",
            productImage: "",
            productType: "",
            variations: {},
            TotalQty: 0,
            sizesQty: [],
            productionType: "",
            fileHandle: "",
            artType: "",
            price: 0,
            precoTotal: 0,
            width: null,
            height: null,
            area: null,
            package: {
              width: 0,
              height: 0,
              length: 0,
              weight: 0
            },
            led: {
              texto:'',
              cor:'',
              fonteTexto:'',
              orientacaoTexto:'',
              largura: 0,
              altura: 0
            },
            ledMeasures: {
              width: 0,
              height: 0,
              squareUnit: 0,
            },
          },
          acabamentos: [],
          selectedFinishes: [],
          selectedFinishesTotalPrice: 0,
          groupSizes: {},
          isLedCor: false,
          selectedLedColor: "Branco Quente",
          selectedLedColorHex: "#FFF1E0",
          measuresWidth: 0,
          measuresHeight: 0,
          minQty: 1,
          maxQty: 9999,
          skuPrice: 0,
          sendArtMsg: '',
          selectedPrice: 0,
          selectedQty: 1,
          selectedPriceToCart: 0,
          selectedProductionTime: "economico",
          selectedFileHandle: "Padrão",
          selectedProductionTimeDays: 0,
          selectedFinishesDetails: [],
          attachSingleFileSelected: false,
          attachMutipleFileSelected: false,
          descriptionsVisible: null,
          quantidadeSkuSelecionado: 0,
          facaPrecoExtra: 0,
          artType: [
            {id: 1, name:"Enviar minha arte"},
            {id: 2, name:"Solicitar Criação de arte"},
            //{id: 3, name:"Criar Minha Arte Online"}
          ],
          attachType:[],
          attachedFile: '',
          attachedType: '',
          attachedFileUrl: '',
          artCreationPrice: 0,
          selectedArtType: 1,
          productionRules: [
            {name: "economico", time: 0},
            {name: "expresso", time: 0}
          ],
          fileHandle: [
            {name:"Padrão", price:0},
            {name:"Profissional", price:21},
          ],
          fileHandlePrice: 0,
          validationSendArtErrors: {
            name: ''
          },
          validationArtCreationErrors: {
            name: '',
            email: '',
            phone: '',
          },
          sendArtForm: {
            //name: '',
            desc: '',
            accepted: false
          },
          artCreationForm: {
            name: '',
            email: '',
            phone: '',
            logo: '',
            artRef: '',
            price: 0,
            accepted: false
          },
          hasSizes: {
            has: false,
            sizes: null,
          },
          selectedSizes: {},
          ledColors: [
            {name: "Branco Quente", hexa:"#FFF1E0"},
            {name: "Branco Frio", hexa:"#FFF"},
            {name: "Rosa", hexa:"#FF007F"},
            {name: "Azul Claro", hexa:"#72FFFF"},
            {name: "Azul Escuro", hexa:"#0000ff"},
            {name: "Vermelho", hexa:"#ff0000"},
            {name: "Laranja", hexa:"#F98404"},
            {name: "Amarelo Âmbar", hexa:"#ffbf00"},
            {name: "Amarelo", hexa:"#FFFF00"},
            {name: "Verde", hexa:"#39ff14"},
            {name: "Roxo", hexa:"#9400d3"},
          ],
          maskaPhone: {
            mask: "(##)#####-####"
          },
          selectedFinishesOn: [],
          selectedFinishesOnTotal: 0
        }
    },
    computed: {
      measuresArea() {
        return (this.measuresWidth * this.measuresHeight)
      },
      productArtCreationPrice() {
        let price = 0
        if(this.product.customizacao.criacao_arte) {
          price = parseFloat(this.product.customizacao.criacao_arte.preco)
          price = this.formatCurrency(price)
        }
        
        return price
      },
      attachType() {
        let attach = []
        if(this.product.tipos_anexo.length > 0) {
          for (const item of this.product.tipos_anexo) {
            attach.push(item.nome)
          }
          
        }
        return attach
      }
    },
    watch: {
      selectedQty(newVal) {
        // console.log("newVal", newVal)
        this.selectedPrice = this.skuPrice * this.selectedQty
      },
      selectedFileHandle(newVal) {
        // console.log("newVal", newVal)
        if (newVal == "Profissional") {
          this.fileHandlePrice = 21
          this.selectedPrice = this.selectedPrice + 21
        } else {
          this.fileHandlePrice = 0
          this.selectedPrice = this.selectedPrice - 21
        }
      },
      selectedProductionTime(newVal, oldVal) {
        if (newVal == 'economico') {
          // this.selectedPrice = this.product.preco.economico + this.fileHandlePrice
          this.skuPrice = this.product.preco.economico
          this.selectedPriceToCart = this.product.preco.economico
        } else {
          //const teste = this.selectedIdPrices.expresso
          // this.selectedPrice = this.product.preco.expresso + this.fileHandlePrice
          this.skuPrice = this.product.preco.expresso
          this.selectedPriceToCart = this.product.preco.expresso
        }
      },

      selectedLedColor(newVal, oldVal) {
        // console.log("COR", newVal)
        //this.led.cor = newVal
        if (newVal === "Branco Quente") {
          this.selectedLedColorHex = "#FFF1E0"
        }
        if (newVal === "Branco Frio") {
          this.selectedLedColorHex = "#FFF"
        }
        if (newVal === "Rosa") {
          this.selectedLedColorHex = "#FF007F"
        }
        if (newVal === "Azul Claro") {
          this.selectedLedColorHex = "#72FFFF"
        }
        if (newVal === "Azul Escuro") {
          this.selectedLedColorHex = "#0000ff"
        }
        if (newVal === "Vermelho") {
          this.selectedLedColorHex = "#ff0000"
        }
        if (newVal === "Laranja") {
          this.selectedLedColorHex = "#F98404"
        }
        if (newVal === "Amarelo Âmbar") {
          this.selectedLedColorHex = "#ffbf00"
        }
        if (newVal === "Verde") {
          this.selectedLedColorHex = "#39ff14"
        }
        if (newVal === "Amarelo") {
          this.selectedLedColorHex = "#FFFF00"
        }
        if (newVal === "Roxo") {
          this.selectedLedColorHex = "#9400d3"
        }
      },
      
    },
    methods: {
      ...mapActions(useCartStore, ['addItem']),

      updateCharacterCount() {
        this.characterCount = this.sendArtForm.desc.length;
      },

      toggleFinishDescription(attribute) {
        this.descriptionsVisible = this.descriptionsVisible === attribute ? null : attribute;
        this.displayHintTratamento = false
        //this.descriptionsVisible[attribute] = !this.descriptionsVisible[attribute];
      },

      isFinishDescriptionVisible(attribute) {
        return this.descriptionsVisible === attribute;
        //return this.descriptionsVisible[attribute] || false;
      },

      async getProductSizes() {
        if (this.product.quantidade_tamanho != 'Não') {              
          this.hasSizes.has = true;
          this.hasSizes.sizes = this.product.quantidade_tamanho;

          // console.log("PEGOU OS TAMANHOs", this.hasSizes)
          
          const sizesArray = Object.values(this.hasSizes.sizes);
          // console.log("PEGOU OS TAMANHOs 2", sizesArray)
          sizesArray.forEach(size => {
            this.groupSizes[size] = 0;
            this.selectedSizes[size] = 0;
          });              
        }
      },

      calculateCost(artType, fileHandle = 'Padrão') { // Default 'Padrão' for clarity
      if (artType === 2 && fileHandle === 'Profissional') {
        if (this.hasMeasures) {
          if ((this.skuPrice * this.measuresArea) < this.skuPrice) {
              return this.skuPrice * this.selectedQty + 50;
          }
          return ((this.skuPrice * this.selectedQty) * this.measuresArea) + 50;
        } else {
          return (this.skuPrice * this.selectedQty) + 50;
        }
      } else if (artType === 2) {
        if (this.hasMeasures) {
          if ((this.skuPrice * this.measuresArea) < this.skuPrice) {
            return (this.skuPrice * this.selectedQty) + this.artCreationPrice;
          }
          return ((this.skuPrice * this.selectedQty) * this.measuresArea) + this.artCreationPrice;
        } else {
          return (this.skuPrice * this.selectedQty) + (this.selectedQty * this.selectedFinishesOnTotal) + this.artCreationPrice;
        }
        
      } else if (fileHandle === 'Profissional') {
        if (this.hasMeasures) {
          if ((this.skuPrice * this.measuresArea) < this.skuPrice) {
              return this.skuPrice * this.selectedQty + 21;
          }
          return ((this.skuPrice * this.selectedQty) * this.measuresArea) + 21;
        } else {
          return (this.skuPrice * this.selectedQty) + 21 + (this.selectedQty * this.selectedFinishesOnTotal);
        }
        
      } else if (artType === 1 && fileHandle === 'Padrão') {
        if (this.hasMeasures) {
          if ((this.skuPrice * this.measuresArea) < this.skuPrice) {
              return this.skuPrice * this.selectedQty;
          }
          return (this.skuPrice * this.selectedQty) * this.measuresArea;
        } else {
          return (this.skuPrice * this.selectedQty) + (this.selectedQty * this.selectedFinishesOnTotal)
        }
      }
      return 10; // Or any default value you want for unmatched conditions
    },

    calculateCostWithSizes(artType, fileHandle = 'Padrão') { // Default 'Padrão' for clarity
      if (artType === 2 && fileHandle === 'Profissional') {
        return (this.skuPrice * (Object.values(this.selectedSizes).reduce((a, b) => a + b, 0))) + 50;
      } else if (artType === 2) {
        return (this.skuPrice * (Object.values(this.selectedSizes).reduce((a, b) => a + b, 0))) + parseFloat(this.artCreationPrice);
      } else if (fileHandle === 'Profissional') {
        return (this.skuPrice * (Object.values(this.selectedSizes).reduce((a, b) => a + b, 0))) + 21;
      } else if (artType === 1 && fileHandle === 'Padrão') {
        return this.skuPrice * (Object.values(this.selectedSizes).reduce((a, b) => a + b, 0));
      }
      return 10; // Or any default value you want for unmatched conditions
    },

      validateArtCreationForm() {
        let isValid = true;
        Object.keys(this.validationArtCreationErrors).forEach(key => {
            if (!this.artCreationForm[key] || this.artCreationForm[key].trim() === '') {
                this.validationArtCreationErrors[key] = 'Este campo é obrigatório';
                isValid = false;
            } else {
                this.validationArtCreationErrors[key] = '';
            }
        });
        
        return isValid;
      },
      validateSendArtForm() {
          let isValid = true;
          Object.keys(this.validationSendArtErrors).forEach(key => {
              if (!this.sendArtForm[key] || this.sendArtForm[key].trim() === '') {
                  this.validationSendArtErrors[key] = 'Este campo é obrigatório';
                  isValid = false;
              } else {
                  this.validationSendArtErrors[key] = '';
              }
          });
          
          return isValid;
      },

      handleFileUploaded(timestampedName, attachedType) {
        // console.log('File uploaded with timestamped name:', timestampedName);
        // console.log('File uploaded with teste:', attachedType);
        this.attachedFile = timestampedName;
        this.attachedType = attachedType;
        this.attachedFileUrl = `https://padrao-color.b-cdn.net/uploads-clientes/${timestampedName}`
        if (timestampedName && attachedType == "Arquivo Único") {
          this.attachSingleFileSelected = true;
        }
        if (timestampedName && attachedType != "Arquivo Único") {
          this.attachMutipleFileSelected = true;
        }
        // Handle the timestampedName as needed
      },

      removeUploadedFile() {
        this.attachSingleFileSelected = false;
        this.attachMutipleFileSelected = false;
      },

      getAcabamentosPrices(value, finish) {

        // console.log("ACABAMENTO VALUE", value);
        // console.log("ACABAMENTO FINISH", finish);
        
        const selectedAcabamentos = this.acabamentos.filter(acabamento => this.selectedFinishes.includes(acabamento.nome));

        // console.log("ACABAMENTO 0", selectedAcabamentos);
        
        const electedAcabamentos2 = selectedAcabamentos.map(acabamento => {
          // const totalQuantity = this.calculateTotalQuantity(acabamento.multiplica, this.selectedQty);
          return {
            id: acabamento.id,
            nome: acabamento.nome,
            quantidade: value,
            multiplica: acabamento.multiplica,
            // precoTotal: totalQuantity * acabamento.preco
            precoTotal: acabamento.preco,
            prazo: acabamento.prazo
          };
        });

        // console.log("ACABAMENTO 1", electedAcabamentos2);

        // Find the object with "nome" equal to "Serrilha"
        let updateElectedAcabamentos2 = electedAcabamentos2.find(item => item.nome === finish);

        // Update the "quantidade" for the found object
        if (updateElectedAcabamentos2) {
            // console.log("ACABAMENTO IF 1", updateElectedAcabamentos2);
            updateElectedAcabamentos2.quantidade = value; // Update "quantidade" to the desired value
        }

        if (updateElectedAcabamentos2 && updateElectedAcabamentos2.multiplica == 'milheiro') {
          // console.log("ACABAMENTO IF 2", electedAcabamentos2);
            updateElectedAcabamentos2.precoTotal = updateElectedAcabamentos2.precoTotal * value; // Update "quantidade" to the desired value
        }

        // console.log("ACABAMENTO 2", updateElectedAcabamentos2);

        // console.log("ACABAMENTO 3", electedAcabamentos2);

        this.selectedFinishesDetails = [];
        this.selectedFinishesDetails.push(...electedAcabamentos2);
        
        let finishesTotalPrice = 0;
        for(const prazo of electedAcabamentos2){

          this.selectedFinishesProductionTime = this.selectedFinishesProductionTime + prazo.prazo

          finishesTotalPrice += prazo.precoTotal;
          
        }

        this.selectedFinishesTotalPrice = finishesTotalPrice;
        // this.selectedValor = this.selectedValor + finishesTotalPrice;

        return electedAcabamentos2
      },

      onUpdateFinishes(event, data) {
        if (event === 0) {

          const selectedAcabamentos = this.acabamentos.filter(acabamento => this.selectedFinishes.includes(acabamento.nome));
          const filteredArrayToDeduct = selectedAcabamentos.filter(item => item.nome == data);
          const deductPrice = filteredArrayToDeduct[0].preco;
          this.selectedValor = this.selectedValor - deductPrice

          const index = this.selectedFinishes.indexOf(data);

          this.selectedFinishes.splice(index, 1);
          
          this.getAcabamentosPrices(event, data);
          // // console.log("ENTROU NO 0");
          return
        }

        const newValue = event;

        if (this.previousValue !== null && this.previousValue !== newValue) {
          // console.log("Old Value:", this.previousValue);
          // console.log("New Value:", newValue);
          const selectedAcabamentos = this.acabamentos.filter(acabamento => this.selectedFinishes.includes(acabamento.nome));
          const filteredArray = selectedAcabamentos.filter(item => item.nome == data);

          if (filteredArray.length > 0) {
            const pricePerUnit = filteredArray[0].preco;

            if (this.previousValue < newValue) {
              // console.log("Adiciona");
              const addPrice = pricePerUnit * (newValue - this.previousValue);
              // console.log("add Price:", addPrice);
              this.selectedValor += addPrice;
            } else {
              // console.log("Reduz");
              const minusPrice = pricePerUnit * (this.previousValue - newValue);
              // console.log("minus Price:", minusPrice);
              this.selectedValor -= minusPrice;
            }
          }
        }

        // Update the previous value to the current value
        this.previousValue = newValue;

        if (this.selectedFinishes.includes(data)) {
          // const finishToUpdate = this.selectedFinishesDetails.filter(item => item.nome == data);
          
          // finishToUpdate[0].quantidade = event

          // // console.log("HAVE 4", finishToUpdate)
          this.getAcabamentosPrices(event, data);

          return
        }

        this.selectedFinishes.push(data)

        if (event === 1)  {
          // console.log("ACABA", data)
          const selectedAcabamentos = this.acabamentos.filter(acabamento => this.selectedFinishes.includes(acabamento.nome));
          
          const filteredArray = selectedAcabamentos.filter(item => item.nome == data);
          
          const addPrice = filteredArray[0].preco;
          this.selectedValor = this.selectedValor + addPrice
        }

        // // console.log("ENTROU NO 1");

        this.getAcabamentosPrices(event, data);
      },
      onUpdateFinishes2(event, data) {
          // console.log("UPDATE FINISHES", data);
          
          
          let finishdata = { ...data }; // Create a copy to avoid modifying the original object
          finishdata.quantidade = event;

          const existingItemIndex = this.selectedFinishesOn.findIndex(item => item.id === finishdata.id);
          // console.log("teste faca", existingItemIndex)
          // console.log("teste faca", event)

          if (existingItemIndex !== -1) {
              // If item exists, update its quantidade
              this.selectedFinishesOn[existingItemIndex].quantidade = event;

              // Remove the item if quantidade is 0
              if (event === 0) {
                  this.selectedFinishesOn.splice(existingItemIndex, 1);
                  if (data.nome == 'Corte e Vinco') {
                    this.escolheFaca = false
                    this.facaTipo = 1
                    this.facaPrecoExtra = 0
                  }
              }
          } else {
              // If item does not exist and quantidade is not 0, push it to the array
              if (event !== 0) {
                  this.selectedFinishesOn.push(finishdata);
                  if (data.nome == 'Corte e Vinco') {
                    this.escolheFaca = true
                    // console.log("preço faca")
                  }
              }
          }

          this.selectedFinishesOnTotal = this.selectedFinishesOn.reduce((total, item) => {
            // console.log("MILHEIRO", item)
            if (item.multiplica === "milheiro") {
                const multiplicaMilheiro = Math.round(this.quantidadeSkuSelecionado / 1000)
                return total + (item.preco * (item.quantidade * multiplicaMilheiro));
            }
            return total + (item.preco * item.quantidade);
          }, 0);

          if (this.selectedFinishesOn.length < 1) {
            this.escolheFaca = false
            this.facaTipo = 1
            this.facaPrecoExtra = 0
          }
          
          let productFinishesTotalProductionDays = 0
          productFinishesTotalProductionDays = this.selectedFinishesOn.reduce((sum, item) => sum + item.prazo, 0);
          this.finishesProductionDays = productFinishesTotalProductionDays

          // console.log("UPDATE FINISHES 2", this.selectedFinishesOn);
          // console.log("Total Value:", this.selectedFinishesOnTotal);
      },

      addToCart() {

        // if (this.selectedArtType == 1 && !this.validateSendArtForm()) {
        //   this.sendArtMsg = "Informe o nome da sua arte no campo 'Nome da arte'"
        //   return; // Impede a submissão se a validação falhar
        // }

        if (this.selectedArtType == 1 && !this.sendArtForm.accepted) {
          this.sendArtMsg = "É necessário aceitar os termos de envio de arte."
          return; // Impede a submissão se a validação falhar
        }

        if (this.selectedArtType == 2 && !this.validateArtCreationForm()) {
          this.sendArtMsg = "Informe os campos para criação de arte"
          return; // Impede a submissão se a validação falhar
        }

        if (this.selectedArtType == 2 && !this.artCreationForm.accepted) {
          this.sendArtMsg = "É necessário aceitar os termos de criação de arte."
          return; // Impede a submissão se a validação falhar
        }

        if (this.selectedArtType == 1 && !this.attachedFileUrl) {
          this.sendArtMsg = "Anexe sua arte para proseguir com a compra"
          return; // Impede a submissão se a validação falhar
        }
        
        if ( Object.keys(this.groupSizes).length > 0 && Object.values(this.selectedSizes).reduce((a, b) => a + b, 0) == 0 ) {
          this.sendArtMsg = "Selecione as quantidades"
          return; // Impede a submissão se a validação falhar
        }

        if (this.selectedProductionTime == "economico") {
          this.selectedProductionTimeDays = this.product.prazo.economico; 
        } else {
          this.selectedProductionTimeDays = this.product.prazo.expresso;
        }

        this.cartData.id = this.product.id;
        this.cartData.productName = this.product.nome ? this.product.nome : 'sem nome';
        this.cartData.productImage = this.product.imagem_1;
        this.cartData.variations = this.product.variacoes;
        this.cartData.artType = this.selectedArtType;
        this.cartData.productionType = this.selectedProductionTime;
        this.cartData.productionTime = this.selectedProductionTimeDays
        
        if (this.selectedArtType === 1) {
          this.cartData.descricao = this.sendArtForm.desc;
          let sendArtData = new Object();
          //sendArtData.name = this.sendArtForm.name
          sendArtData.desc = this.sendArtForm.desc
          sendArtData.attach = this.attachedFileUrl
          this.cartData.sendArtData = sendArtData
          // //console.log("ENTROU", sendArtData)

          let arte = new Object();
          let arteAnexosObj = new Object();
          arte.tipo = this.selectedArtType
          arte.preco = 0
          if (this.attachMutipleFileSelected) {
            arte.anexos = this.attachedMultipleFilesUrl
            this.cartData.arte = arte
          } else {
            arte.anexos = []
            arteAnexosObj.tipoArquivo = this.attachedType
            arteAnexosObj.nome = this.attachedFile
            arteAnexosObj.url = this.attachedFileUrl
            arte.anexos.push(arteAnexosObj)
            this.cartData.arte = arte
          }
          
        }

        if (this.selectedArtType === 2) {
          this.artCreationPrice = parseFloat(this.product.customizacao.criacao_arte.preco)
          let sendArtCreationData = new Object();
          sendArtCreationData.name = this.artCreationForm.name
          sendArtCreationData.email = this.artCreationForm.email
          sendArtCreationData.phone = this.artCreationForm.phone
          sendArtCreationData.attach = this.artCreationAttachs
          sendArtCreationData.price = this.artCreationPrice
          this.cartData.sendArtCreationData = sendArtCreationData

          // console.log("ENTROU", sendArtCreationData)

          // console.log("produto", this.artCreationPrice)

          let arte = new Object();
          arte.tipo = this.selectedArtType
          arte.nomeCliente = this.artCreationForm.name
          arte.phone = this.artCreationForm.phone
          arte.email = this.artCreationForm.email
          arte.logo = this.artCreationForm.logo
          arte.arteReferencia = this.artCreationForm.artRef
          arte.preco = this.artCreationPrice
          this.cartData.arte = arte
        }
        
        //this.cartData.fileHandle = "Padrão";
        if (this.selectedFileHandle == 'Padrão') {
          let tratamentoArquivo = new Object();
          tratamentoArquivo.tipo = "Padrão";
          tratamentoArquivo.preco = 0;
          this.cartData.tratamentoArquivo = tratamentoArquivo;
        } else {
          let tratamentoArquivo = new Object();
          tratamentoArquivo.tipo = "Profissional";
          tratamentoArquivo.preco = 21;
          this.cartData.tratamentoArquivo = tratamentoArquivo;
        }
        this.cartData.price = this.skuPrice;

        this.cartData.precoTotal = parseFloat(((this.skuPrice * this.selectedQty) + this.cartData.tratamentoArquivo.preco + this.artCreationPrice).toFixed(2))

        if (Object.keys(this.groupSizes).length > 0) {
          this.cartData.precoTotal = this.skuPrice * Object.values(this.selectedSizes).reduce((a, b) => a + b, 0);
        } else {
          this.cartData.precoTotal = this.skuPrice * this.selectedQty
        }

        if (Object.keys(this.groupSizes).length > 0) {
          this.cartData.quantidade = Object.values(this.selectedSizes).reduce((a, b) => a + b, 0);
          this.cartData.TotalQty = Object.values(this.selectedSizes).reduce((a, b) => a + b, 0);
          this.cartData.quantidadePorTamanho = this.selectedSizes
        } else {
          this.cartData.quantidade = this.selectedQty
          this.cartData.TotalQty = this.selectedQty
        }

        this.cartData.precoTotalAcabamentos = 0
        if (this.selectedFinishesOn.length > 0) {
          // console.log("IN", this.selectedFinishesOn)
          let acabamentos = [];
          this.selectedFinishesOn.forEach(element => {
            let acabamento = new Object();
            acabamento.id = element.id
            acabamento.nome = element.nome
            acabamento.quantidade = element.quantidade
            acabamento.multiplica = element.multiplica
            acabamento.preco = element.preco
            acabamento.prazo = element.prazo
            acabamentos.push(acabamento)
          });
          this.cartData.acabamentos = acabamentos
          this.cartData.precoTotalAcabamentos = this.selectedFinishesOnTotal + this.facaPrecoExtra
        }

        if (this.hasMeasures) {
          this.cartData.largura = this.convertToNumber(this.measuresWidth)
          this.cartData.altura = this.convertToNumber(this.measuresHeight)
        }

        if (this.hasMeasures) {
          this.cartData.width = this.measuresWidth
          this.cartData.height = this.measuresHeight
          this.cartData.area = this.measuresArea
        }

        if (this.isLedCor) {
            this.cartData.led.cor = this.selectedLedColorHex
        }
    
        // Check if already exist product at cart
        const savedCart = localStorage.getItem('cart')
        if (savedCart) {
            const cartItens = localStorage.getItem('cart')
            const parsedCartItens = JSON.parse(cartItens)

            parsedCartItens.push(this.cartData);

            const stringifiedCart = JSON.stringify(parsedCartItens)
            localStorage.setItem('cart', stringifiedCart)

        } else {

            const dataToCart = []
            dataToCart.push(this.cartData);

            const stringifiedCart = JSON.stringify(dataToCart)
            localStorage.setItem('cart', stringifiedCart)

        }

        this.addItem(this.cartData);
        this.sendArtMsg = '';
        this.closeModal();
      },

      formatCurrency(valor) {
        const valorFormatado = valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return valorFormatado
      },
      closeModal() {
        this.$emit('close');
      },
      submitForm() {
        // Emit custom event with data
        this.$emit('customEvent', this.modalData);
        this.closeModal();
      },
      async loadUserData(token) {
          const data = await this.getUser(token);
          return data
      },
    },
    async mounted() {
      // console.log("Produto", this.product)
      this.skuPrice = this.product.preco.economico
      this.selectedPrice = this.product.preco.economico
      this.selectedPriceToCart  = this.product.preco.economico
      this.selectedQty = this.product.initialQty ? this.product.initialQty : 1

      await this.getProductSizes();

      this.quantidadeSkuSelecionado = this.product.quantidade ? this.product.quantidade : 0

      if (this.product.acabamentos) {
        this.acabamentos = this.product.acabamentos
      }

      if (this.product.customizacao.tipo === 3) {
        this.isLedCor = true;
        this.selectedLedColorHex = "#FFF1E0";
        this.sendArtForm.accepted = true;
        this.attachedFileUrl = "Não se aplica";
      }
      
      if (localStorage.getItem("user_token")) {
        const userToken = localStorage.getItem("user_token")
        const user = await this.loadUserData(userToken)
        // console.log("USER", user)
        this.artCreationForm.name = user.nome
        this.artCreationForm.email = user.email ? user.email : user.email_cadastro
        this.artCreationForm.phone = user.telefone_1
      }
    },
  }
</script>
  
<style lang="scss" scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    border-radius: 5px;
    padding: 20px;
    width: 90vw;
    z-index: 1001;
    display: block;
    max-width: 900px;
    position: relative;
    overflow: auto;
    text-align:left;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .attach-multiple {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .warning-msg {
    background: #ffb400;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    text-align: center;
  }

  .error {
    color: red;
    font-size: 0.9rem;
  }
  input[type=text] {
    &.input-error {
      border-color: red;
    } 
  }

  .product-sizes {
    display: flex;
    margin: 10px 0;
    label {
      width: 30%;
      font-weight: 500;
    }
    .product-sizes-container {
      display: flex;
      flex-wrap: wrap;
    }
    .input-number-container {
      width: 50%;
      display: flex;
      align-items: center;
      padding: 8px 15px;
      .label-input-number {
        margin-bottom: 0;
        margin-right: 10px;
        flex: auto;
        font-weight: 400;
      }
    }
  }

  .product-led-color > label {
      width: 30%;
      font-weight: 500;
      font-size: 0.9rem;
    }
    .product-led-color {
      display: flex;
      .led-colors {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
      }
      input[type=radio] {
        visibility: hidden;
      }
      .led-color-radio {
        width: 30px;
        height: 30px;
        border-radius: 21px;
        border: 4px solid #f5f5f5;
        cursor: pointer;
        &.selected-led-color {
          box-shadow: 0px 0px 0px 1px #ff4341;
        }
      }
    }

  
  .modal-body {
    margin-bottom: 20px;
    display: grid; 
    grid-template-columns: 1fr 1.5fr; 
    grid-template-rows: 1fr; 
    gap: 0px 10px; 
    grid-template-areas: 
      ". ."; 
    label {
      font-weight: 500;
    }
    img {
      max-width: 100%;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: left;
      }
    }
    .modal-price {
      font-weight: 500;
      font-size: 1.9rem;
    }
    .product-finishes {
      display: flex;
      margin: 10px 0;
      .product-finishes-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .label-finishes {
          position: relative;
        }
      }
      .input-number-container {
        width: 50%;
        padding: 8px 15px;
        .label-input-number {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          margin-right: 10px;
          flex: auto;
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
    .product-finishes, .product-file-handling {
      margin-top: 20px;
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
      label {
        font-weight: 300;
      }
    }
    .help-balloon {
      position: fixed;
      width: 400px;
      font-size: 0.9rem;
      background: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      top: 29px;
      box-shadow: 1px -8px 28px -24px #000;
      z-index: 999;
      border: 1px solid #f9c7c6;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .help-balloon-close {
      // display: none;
      text-align: right;
      font-weight: 600;
      cursor: pointer
    }
    .product-production-type {
      margin-top: 20px;
      small {
        color: #a1a1a1;
        margin-left: 10px;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .product-art-type {
      .product-art-type-input-container {
        display: flex;
        margin: 15px 0;
        label {
          width: 20%;
          font-weight: 500;
          font-size: 0.9rem;
        }
        select {
          flex: auto;
          border: 1px solid #D9D9D9;
          border-radius: 50px;
          font-size: 0.8em;
          padding: 5px 20px 5px 5px;
        }
      }        
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .button-container {
      width: 45%;
      margin: 0;
    }
    .total-price {
      width: 45%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: flex-end;
      p {
        margin: 0;
        line-height: 30px;
        font-size: 1.8rem;
        span {
          font-weight: 600;
        }
      }
    }
  }
  
  .default-button {
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .default-button:hover {
    background-color: #e1e1e1;
  }
  
</style>
  