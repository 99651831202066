<template>
    <div class="cookie-policy" v-if="showCookiePolicy">
        <p>
           <strong>Padrão Color e os cookies:</strong> utilizamos cookies para melhorar a sua experiência no site.
           <br>
           Ao continuar navegando, você concorda com a nossa 
           <router-link :to="{ name: 'policies'}">
                Política de Privacidade.
            </router-link>
        </p>
        <button class="button button-secondary" @click.prevent="acceptCookiePolicy">Aceitar e fechar</button>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                cookiePolicy: true,
                showCookiePolicy: true,
            }
        },
        methods: {
            acceptCookiePolicy() {
                localStorage.setItem('cPol', this.cookiePolicy);
                this.showCookiePolicy = false;
            }
        },
    }
</script>
<style lang="scss" scoped>
    .cookie-policy {
        display: flex;
        background: #fff;
        margin: 0 30px;
        padding: 20px;
        border-radius: 10px;
        position: fixed;
        bottom: 25px;
        font-size: 0.9rem;
        text-align: left;
        align-items: center;
        width: calc(100% - 60px);
        border: 1px solid #ff434191;
        z-index: 999;
        P {
            margin: 0;
            padding-right: 30px;
            flex: 1
        }
        strong {
            font-weight: 600;
        }
        .button {
            width: 200px
        }
    }
    @media (max-width:600px){
        .cookie-policy {
        display: block;
        text-align: center;
        P {
            margin-bottom: 15px;
            padding: 0;
        }
    }
    }
    
</style>