import axios from "axios"
import { environment } from '@/stores/environment';

export const HelpersApiMixin = {
  data() {
    return {
      // baseUrl: 'http://137.184.148.25/api', // Base URL for the API
      baseUrlBunny: 'https://br.storage.bunnycdn.com/padraocolor/uploads-clientes/', // Base URL for the API
    };
  },
  methods: {
    
    async axiosInstanceBunny(fileType) {
      const AccessKey = '098bf920-6d7a-4544-b2c89402651e-9005-4d9d';
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrlBunny,
        headers: {
          'AccessKey': `${AccessKey}`,
          'Content-Type': `${fileType}`
        }
      });
    },
    async axiosInstanceViaCep(zipcode) {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: `https://viacep.com.br/ws/${zipcode}`,
      });
    },

    async axiosInstanceJadlog() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'https://padraocolor.com.br/api/v2/general', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceApi() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'https://padraocolor.com.br/api/v2', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceApiLocal() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'http://localhost:3000/api', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceCielo() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'https://padraocolor.com.br/api/v2/general', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceCieloLocal() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'http://localhost:3000/api/general', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceAPIPadraoColor() {
      // Create an Axios instance with the Authorization header
      const token = `${environment.API_TOKEN_DEFAULT}`;
      return axios.create({
        baseURL: `${environment.API_URL}/site`, // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
    },

    async bunnyUpload(fileName, rawFile, fileType) {
      try {
        const instance = await this.axiosInstanceBunny(fileType);
        const response = await instance.put(`${fileName}`, rawFile);
        // console.log("Upload successfully.", response.data);
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async getHolidays() {
      try {
        const instance = await this.axiosInstanceAPIPadraoColor();
        const response = await instance.get('/feriados?data_inicial=01/12/2024&data_final=31/12/2025');
        // console.log("Feriados", response.data.feriados);
        return response.data.feriados
      } catch (error) {
        console.error("Erro ao carregar feriados", error);
      }
    },

    async getDataFromzipcode(zipcode) {
      try {
        const instance = await this.axiosInstanceViaCep(zipcode);
        const response = await instance.get('/json/');
        // console.log("Upload successfully.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async getJadlogShippingData(jadlogData) {
      try {
        const instance = await this.axiosInstanceJadlog();
        const response = await instance.post('/jadlog-quotation/', jadlogData);
        // console.log("Jadlog Shipping Data.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },
    async jadlogTracking(codigo) {
      try {
        const instance = await this.axiosInstanceJadlog();
        const requestBody = {
          consulta: [
            {
              codigo: codigo
            }
          ]
        };
        const response = await instance.post('/jadlog-tracking/', requestBody);
        // console.log("Jadlog Tracking Data.", response.data);
        return response.data
      } catch (error) {
        console.error("Error Jadlog", error);
      }
    },
    async getBalcoesJadlog(cep) {
      try {
        const instance = await this.axiosInstanceJadlog();
        const response = await instance.get('/jadlog-location/', {
          params: { cep: cep }
        });
        // console.log("Balcões.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async cieloCreditCardTransaction(paymentData) {
      try {
        const instance = await this.axiosInstanceCielo();
        //const instance = await this.axiosInstanceCieloLocal();
        // console.log("Cielo payload.", paymentData);
        const response = await instance.post('/cielo-payment/', paymentData);
        // console.log("Cielo resposta.", response.data);
        return response.data
      } catch (error) {
        console.error("Error Cielo", error);
        throw new Error(error.response?.data?.message || 'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async cieloCancelTransaction(paymentId, amount) {
      try {
        const instance = await this.axiosInstanceCielo();
        //const instance = await this.axiosInstanceCieloLocal();
        const response = await instance.post(`/cielo-cancel-payment/${paymentId}/${amount}`);
        // console.log("Cielo Cancel resposta.", response.data);
        return response.data
      } catch (error) {
        console.error("Error Cielo", error);
        throw new Error(error.response?.data?.message || 'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async pixTransaction(paymentData) {
      try {
        const instance = await this.axiosInstanceCielo();
        const response = await instance.post('/pix-payment/', paymentData);
        // console.log("Pix.", response.data);
        return response.data
      } catch (error) {
        console.error("Error Pix", error);
        throw new Error(error.response?.data?.message || 'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async validateCupom(cupomData) {
      try {
        const instance = await this.axiosInstanceApi();
        //const instance = await this.axiosInstanceApiLocal();
        
        const response = await instance.get(`/cupom/valida?codigo=${cupomData.codigo}&valorDoPedido=${cupomData.valorDoPedido}&categoriasDoPedido=${cupomData.categoriasDoPedido}&idCliente=${cupomData.idCliente}&token=${cupomData.token}`);
        // console.log("Cupom:", response.data);
        return response.data
      } catch (error) {
        console.error("Error Cielo", error);
        // return error.response?.data?.message ||
        //        error.response?.data?.error ||
        //        message ||
        //        'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.';
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async setUsedCupom(cupomData) {
      try {
        const instance = await this.axiosInstanceApi();
        //const instance = await this.axiosInstanceApiLocal();
        
        const response = await instance.post(`/cupom/cupom-usado`, cupomData);
        // console.log("Cupom:", response.data);
        return response.data
      } catch (error) {
        console.error("Error CUPOM", error);
        
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async checkCnpj(cnpj) {
      try {
        const response = await axios.get(`https://padraocolor.com.br/api/v2/general/check-doc?cnpj=${cnpj}`);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async createFavorite(favoriteData) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.post(`/favorite`, favoriteData);
        // console.log("Favorito Criado:", response.data);
        return response.data
      } catch (error) {
        console.error("Error create Favorite", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async sendVendaCorporativaEmail(emailData) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.post(`/mail/venda-corporativa`, emailData);
        // console.log("Email Enviado:", response.data);
        return response.data
      } catch (error) {
        console.error("Error sending email", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async sendOrderCompletedEmail(emailData) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.post(`/mail/order-completed`, emailData);
        // console.log("Email Enviado:", response.data);
        return response.data
      } catch (error) {
        console.error("Error sending email", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async sendPixInfoEmail(emailData) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.post(`/mail/pix-info`, emailData);
        // console.log("Email Enviado:", response.data);
        return response.data
      } catch (error) {
        console.error("Error sending email", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    async sendSignUpEmail(emailData) {
      try {
        const instance = await this.axiosInstanceApi();
        const response = await instance.post(`/mail/signup`, emailData);
        // console.log("Email Enviado:", response.data);
        return response.data
      } catch (error) {
        console.error("Error sending email", error);
        throw new Error(error.response?.data?.message ||
          error.response?.data?.error ||
           'Algo inesperado aconteceu. Por favor, tente novamente ou entre em contato com o SAC.');
      }
    },

    
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
